#promo-slider {
    border-radius: 10px;
    background-color: #111;
    overflow: hidden;
}

.promo-slider-slide {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    background-size: cover;
    background-repeat: no-repeat;
}
.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #000000 !important;
  }
  
  .nav-tabs .nav-link.active {
    color: #F10000 !important;
  }
  