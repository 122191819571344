@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;800;900&family=Open+Sans:wght@300;400;600;700&display=swap');

html, body {
    font-family: 'Montserrat';
}

#app-container {
    min-height: calc(100vh - 160px);
    overflow: hidden;
    background-color: #fff;
}

#main-container {
    background-color: #fff;
}

.modal:nth-of-type(even) {
    z-index: 1055 !important;
}

.modal-backdrop.show:nth-of-type(even) {
    z-index: 1055 !important;
}
