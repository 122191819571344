@media only screen and (min-width: 992px) {
    #header-navbar {
        height: 65px;
        width: 100%;
        margin: auto;
        
    }
    #header-menu {
        font-size: 16px;
        justify-content: center;
        align-items: center;
        font-weight: 500;
    }
}

#header-menu a.nav-link.active {
    color: rgb(11, 165, 236);
    font-weight: 600;
}

#header-menu a.nav-link {
    margin: 0 8px;
}

#header-navbar {

}